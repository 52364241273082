export const backendHost = 'https://ooomlk.ru/';

export const initialState = {
    full_name: "",
    contact_phone: "",
    email: "",
    company_name: "",
    comment: "",
}
export const initialCatalogState = {
    name: "",
    contact_phone: "",
    email: "",
    company_name: ""
}